import React, { useContext, useEffect, useState } from 'react';

import FormDefault from '~/components/FormDefault';

import api from '~/services/api';

import { Container } from 'react-bootstrap';
import { Form } from './components/Form';
import { Table } from './components/Table';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './validations';
import useAuth from '~/hooks/useAuth';
import { toast } from 'react-toastify';
import { Context } from '~/context/auth';
import { validaEmail } from '~/utils/functions';

const EdicaoDeConta: React.FC = () => {
  const isUpdate = true;

  const { handleLogout } = useContext(Context);
  const [updateForm, setUpdateForm] = useState(false);

  const auth = useAuth();

  const form = useForm({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (auth.user.loja) {
      setValue('cod_loja', auth.user.loja);
    }
  }, [auth.user.loja]);

  const onCancel = async () => {
    return null;
  };

  const onNew = () => {
    return null;
  };

  const onSave = handleSubmit(async (formData: any) => {
    const { timezone, cod_usuario } = formData;

    setUpdateForm(true);

    if (!validaEmail(formData.email))
      return toast.warning('O Email informado é inválido');

    const update = {
      ...formData,
      timezone: timezone.value,
    };

    const res = await api.put(`/usuario/editar-conta/${cod_usuario}`, update);
    const { message } = res.data;
    handleLogout([]);
    return toast.success(message);
  });

  const handleClearForm = () => {
    return null;
  };

  const onReturnSearch = () => {
    return null;
  };

  return (
    <Container>
      <FormDefault
        codTela={223}
        title="Edição de conta"
        onSave={async () => {
          if (errors?.senha?.type === 'required') {
            toast.warning('Verifique se sua senha atende aos requisitos');
            return;
          }
          await onSave();
        }}
        isUpdate={isUpdate}
        onCancel={() => onCancel()}
        isNew={false}
        onNew={() => {
          onNew();
        }}
        isDelete={false}
        onDelete={() => null}
        onClearFields={() => {
          handleClearForm();
        }}
        onReturnSearch={() => onReturnSearch()}
        showSwitch={false}
        isBackSearch={false}
      >
        <Form form={form} updateForm={updateForm} />
        <Table form={form} />
      </FormDefault>
    </Container>
  );
};

export default EdicaoDeConta;
